'use client'
import { useMemo, useRef } from 'react'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'

import { CardLazyWrapper } from '@components/cards/RaCardLazyWrapper/RaCardLazyWrapper'
import { Loop } from '@utilities/helpers/core.helpers'
import { IsSet } from '@utilities/helpers/get-type-of.helpers'
import { useIntersectionObserver } from '@utilities/hooks/useIntersectionObserver.hooks'

import styles from './ra-slider.module.scss'

const ProductCardsCarousel = dynamic(() =>
  import(
    '@components/carousels/ProductCardsCarousel/ProductCardsCarousel'
  ).then(mod => mod.ProductCardsCarousel)
)

export const RaSlider = ({ slides, maxWidth }) => {
  const ref = useRef(null)
  const { hasIntersected } = useIntersectionObserver(ref)
  const cards = useMemo(() => {
    if (!IsSet(slides)) return null
    const notEmpty = slides.filter(item => item.id)
    return Loop(notEmpty, (slide: any, n: number) => {
      if (!slide) return null

      const props = {
        type: slide?.page_type || slide?.type,
        card: slide,
      }
      switch (slide.type) {
        case 'blog_post':
        case 'page':
          return (
            <CardLazyWrapper
              renderCard="blog"
              {...{ props }}
              key={n}
            />
          )

        case 'blog_category':
          return (
            <CardLazyWrapper
              renderCard="blog-category"
              {...{ props }}
              key={n}
            />
          )

        case 'roundtrip':
        case 'building_block':
          return (
            <CardLazyWrapper
              renderCard="trip"
              {...{ props }}
              key={n}
            />
          )
      }
    })
  }, [slides])

  return (
    <div
      ref={ref}
      className={styles['ra-slider']}>
      <ErrorBoundary>
        {hasIntersected && cards && (
          <ProductCardsCarousel
            slides={cards}
            maxWidth={maxWidth}
          />
        )}
      </ErrorBoundary>
    </div>
  )
}
