'use client'
import { useState } from 'react'
import { RaButton } from '@components/core/index.core'
import { translate } from '@utilities/helpers/core.helpers'

export const RaLoadMoreBlogs = ({
  blogCount,
  initialVisibleBlogCount = 6,
  incrementCount = 6,
  containerId,
  cardIdPrefix,
}) => {
  const [visibleBlogCount, setVisibleBlogCount] = useState(
    initialVisibleBlogCount
  )

  const loadBlogs = () => {
    const newVisibleCardsCount = visibleBlogCount + incrementCount
    const container = document.getElementById(containerId)
    const cards = container.querySelectorAll('[data-name="grid-item"]')
    if (cards?.length) {
      cards.forEach(card => {
        const cardIndex = card.id.replace(cardIdPrefix, '')
        if (parseInt(cardIndex) <= newVisibleCardsCount) {
          ;(card as any).style.display = 'inline'
        }
      })
    }

    setVisibleBlogCount(newVisibleCardsCount)
  }

  return (
    <>
      {blogCount >= visibleBlogCount && (
        <RaButton
          label={translate('SHOW_MORE_RESULTS')}
          onClick={loadBlogs}
        />
      )}
    </>
  )
}
