import { useMemo } from 'react'

import { getImageSizes } from '@utilities/helpers/get-image-sizes.helpers'
import { AcfImageObject, WordpressPostFeaturedImage } from '@typings'

import styles from './ra-background-image.module.scss'

interface RaBackgroundImageProps {
  image: AcfImageObject | WordpressPostFeaturedImage
  quality?: number
  overlayOpacity?: number
  preload?: boolean
  children: React.ReactNode
  width?: number
}

export const RaBackgroundImage = ({
  image,
  children,
  preload = false,
  overlayOpacity = 0.6,
  width,
}: RaBackgroundImageProps) => {
  const sizes = getImageSizes(image.sizes)

  const srcSet = useMemo(() => {
    if (!sizes.length) return null

    return sizes
      .map(s => {
        return `${s.src} ${s.width}w`
      })
      .join(',')
  }, [])

  return (
    <div
      className={styles['ra-background-image']}
      style={
        {
          width: width || '100%',
          '--bg-image-overlay-opacity': overlayOpacity,
        } as React.CSSProperties
      }>
      <img
        src={image.url}
        alt={image.alt}
        width={image.width}
        height={image.height}
        srcSet={srcSet}
        loading={preload ? 'eager' : 'lazy'}
      />
      <div className={styles['ra-background-image__overlay']} />
      <div className={styles['ra-background-image__content']}>{children}</div>
    </div>
  )
}
