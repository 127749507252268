import { Loop, Memoize } from '@utilities/helpers/core.helpers'

import { AcfImageSizesProps } from '@typings'

export const getImageSizes = Memoize((sizes: AcfImageSizesProps) => {
  if (!sizes) return []
  const validKeys: string[] = Object.keys(sizes).filter(item => {
    if (
      (item.includes('image-') ||
        item.includes('custom-size-') ||
        item === 'full' ||
        item === 'medium') &&
      !item.includes('-width') &&
      !item.includes('-height') &&
      !item.includes('square') &&
      !item.includes('thumbnail')
    )
      return item
  })

  // todo
  const image = sizes as any

  const result = Loop(validKeys, (key: string) => {
    return {
      src: image[key],
      width: image[`${key}-width`],
      height: image[`${key}-height`],
    }
  })

  return result.sort((a, b) => {
    return a.width < b.width ? 1 : a.width > b.width ? -1 : 0
  })
})
