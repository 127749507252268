import { RaButton, RaPaper, RaTypography } from '@components/core/index.core'
import { AcfCardCampaignProps } from '@typings'
import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

import styles from './ra-card-campaign.module.scss'

interface IRaCardCampaign {
  title: AcfCardCampaignProps['title']
  cta_link: AcfCardCampaignProps['cta_link']
}

export const RaCardCampaign = ({ title, cta_link }: IRaCardCampaign) => {
  return (
    <div className={styles['card']}>
      <RaPaper elevation="light">
        <div className={styles['card__content']}>
          <RaTypography
            component="span"
            fontFamily="heading">
            {title}
          </RaTypography>
          {typeof cta_link !== 'string' && cta_link?.url && (
            <RaButton
              link={{ ...cta_link, url: stripFrontendUrl(cta_link.url) }}
            />
          )}
        </div>
      </RaPaper>
    </div>
  )
}
