import { RaButton, RaHeading } from '@components/core/index.core'

import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

import styles from './ra-card-cta.module.scss'

import { AcfLinkItemProps } from '@typings'

interface IRaCardCampaign {
  title: string
  cta_link: AcfLinkItemProps
}

export const RaCardCTA = ({ title, cta_link }: IRaCardCampaign) => {
  return (
    <div className={styles['card-cta']}>
      <RaHeading
        component="h3"
        color="dark"
        noPadding>
        {title}
      </RaHeading>
      {typeof cta_link !== 'string' && cta_link?.url && (
        <RaButton link={{ ...cta_link, url: stripFrontendUrl(cta_link.url) }} />
      )}
    </div>
  )
}
