'use client'
import { Fragment, memo, useMemo } from 'react'
import ErrorBoundary from '@error-boundary'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import type {
  BuildingBlockKeyFactsRepeaterItemTableRepeaterProps,
  BuildingBlockPostType,
  RoundtripKeyFactsRepeaterItemTableRepeaterProps,
  RoundtripPostType,
} from '@typings'
import {
  RaButton,
  RaHeading,
  RaIcon,
  RaImage,
  RaPaper,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'
import { RaButtonAddToCart } from '@components/features/RaButtonAddToCart/RaButtonAddToCart'
import { ICONS } from '@constants/icons.constants'
import {
  getCurrentEnv,
  Loop,
  stripFrontendUrl,
  translate,
  unescapeHTML,
} from '@utilities/helpers/core.helpers'

import styles from './ra-card-product.module.scss'
import { useWishlist } from '@/contexts/wishlist.context'

const RaStepmap = dynamic(() =>
  import('@components/features/RaStepmap/RaStepmap').then(mod => mod.RaStepmap)
)

const RaAccordion = dynamic(() =>
  import('@components/features/RaAccordion/RaAccordion').then(
    mod => mod.RaAccordion
  )
)

const RaKeyfactWithoutIcon = dynamic(() =>
  import('@components/features/RaKeyfactWithoutIcon/RaKeyfactWithoutIcon').then(
    mod => mod.RaKeyfactWithoutIcon
  )
)

const RaButtonAddToWishlist = dynamic(() =>
  import(
    '@components/features/RaButtonAddToWishlist/RaButtonAddToWishlist'
  ).then(mod => mod.RaButtonAddToWishlist)
)

const MobileCardsCarousel = dynamic(() =>
  import('@components/carousels/MobileCardsCarousel/MobileCardsCarousel').then(
    mod => mod.MobileCardsCarousel
  )
)

type KeyfactsRepeaterProps =
  | BuildingBlockKeyFactsRepeaterItemTableRepeaterProps
  | RoundtripKeyFactsRepeaterItemTableRepeaterProps

interface RaCardProductProps {
  card: BuildingBlockPostType | RoundtripPostType
  index: number
}

export const RaCardProduct = memo(({ card, index }: RaCardProductProps) => {
  if (!card) return null
  const { type, title, acf, link, featured_image } = card
  if (!acf) return null
  const { enableWishlist } = useWishlist()
  const bbOrRt = type === 'reise-baustein' ? 'bb' : 'rt'
  const repeater = acf[`${bbOrRt}_keyfacts_repeater`]
  const teaser = acf[`${bbOrRt}_teaser_text`]
  const stepmapScript = acf[`${bbOrRt}_location`]
  const images = acf[`rt_modul_teaser_gallery`] || [featured_image]
  const isErlebe = getCurrentEnv() === 'ERLEBE'

  const keyFacts = useMemo(() => {
    if (!repeater || repeater?.length === 0) return null
    if (!repeater[0]?.output_type_table_repeater) return null

    const previewList = repeater[0]?.output_type_table_repeater.filter(
      ({ teaser_checkbox }) => teaser_checkbox
    )

    return Loop(previewList, (item: KeyfactsRepeaterProps, i: number) => {
      return (
        <RaKeyfactWithoutIcon
          directionMobile={'row'}
          item={item}
          key={i}
        />
      )
    })
  }, [repeater])

  const teaserGallery = useMemo(() => {
    if (!images.length) return null
    return Loop(images, (image, i) => (
      <RaImage
        key={i}
        width={300}
        height={225}
        image={image}
      />
    ))
  }, [images, stepmapScript])

  return (
    <RaPaper
      key={`${index}-${card.id}`}
      paddingMobile={0}
      tornEdge
      hoverAnimations={['shadow']}>
      <ErrorBoundary>
        <div
          className={styles['card']}
          style={
            {
              '--stepmap-width-md': bbOrRt === 'bb' ? '300px' : '400px',
              '--stepmap-width-xl': bbOrRt === 'bb' ? '300px' : '500px',
            } as React.CSSProperties
          }>
          <div
            className={clsx(
              styles['card__content'],
              bbOrRt === 'bb' && styles['card__content--bb']
            )}>
            <div className={styles['card__images--desktop']}>
              <div
                data-type={bbOrRt}
                className={clsx(
                  styles['card__images--desktop__images'],
                  isErlebe && styles['card__images--desktop__images__erlebe']
                )}>
                {bbOrRt === 'rt' && acf[`rt_modul_teaser_gallery`].length && (
                  <Fragment>
                    {Loop(acf[`rt_modul_teaser_gallery`], (image, i) => (
                      <RaImage
                        key={i}
                        width={200}
                        height={150}
                        image={image}
                        elevation="light"
                        rotate={i % 2 === 0 ? 'left' : 'right'}
                      />
                    ))}
                  </Fragment>
                )}
                {bbOrRt === 'bb' && (
                  <div className={styles['card__images--desktop__images--bb']}>
                    <RaImage
                      image={featured_image}
                      rotate={index % 2 === 0 ? 'left' : 'right'}
                      elevation="light"
                      width={400}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={styles['card__description']}>
              <div className={styles['card__header']}>
                {bbOrRt === 'rt' ? (
                  <RaTypography
                    component="span"
                    size="s"
                    weight="bold"
                    color="primary"
                    customClasses={styles['card__header--rt']}>
                    {index + 1}
                  </RaTypography>
                ) : (
                  <div
                    className={clsx(
                      styles['card__header--bb'],
                      index + 1 > 9 && styles['card__header--dd-index']
                    )}>
                    <RaIcon icon={ICONS.PUZZLE_INDEX} />
                    <RaTypography
                      component="span"
                      size="l"
                      weight="bold"
                      color="light">
                      {index + 1}
                    </RaTypography>
                  </div>
                )}
                <Link
                  href={stripFrontendUrl(link)}
                  title={unescapeHTML(title.rendered)}>
                  <RaHeading
                    component="h3"
                    color="primary"
                    noPadding>
                    {unescapeHTML(title.rendered)}
                  </RaHeading>
                </Link>
              </div>
              <ul>{keyFacts}</ul>
              <div className={styles['card__details--mobile']}>
                <RaAccordion
                  variant="read-more"
                  title={translate('MORE_INFO')}
                  content={<RaRichText content={unescapeHTML(teaser)} />}
                />
              </div>
              <div className={styles['card__details--desktop']}>
                <RaRichText content={unescapeHTML(teaser)} />
              </div>
              <div className={styles['card__buttons']}>
                <RaButton
                  fullWidth
                  variant="secondary-dark"
                  link={{
                    url: link,
                    title: translate(
                      bbOrRt === 'rt' ? 'VIEW_ROUNDTRIP' : 'VIEW_BUILDINGBLOCK'
                    ),
                  }}
                />
                <RaButtonAddToCart
                  variant="icon"
                  item={card}
                />
              </div>
            </div>
            {teaserGallery && (
              <div className={styles['card__images--mobile']}>
                <MobileCardsCarousel slides={teaserGallery} />
              </div>
            )}
            <div className={styles['card__stepmap']}>
              <RaStepmap
                stepmapScript={stepmapScript}
                index={`${index}-${card.id}`}
              />
            </div>
          </div>
          {enableWishlist && (
            <div className={styles['card__wishlist']}>
              <RaButtonAddToWishlist product={card} />
            </div>
          )}
        </div>
      </ErrorBoundary>
    </RaPaper>
  )
})
