import {
  RaHeading,
  RaImage,
  RaPaper,
  RaTypography,
} from '@components/core/index.core'
import { stripFrontendUrl, translate } from '@utilities/helpers/core.helpers'

import { AcfImageObject } from '@typings'

import styles from './ra-card-blog-category.module.scss'

interface IRaCardBlogCategory {
  type?: 'blog_category'
  card: {
    type: 'blog_category'
    id: number
    slug: string
    link: string
    name: string
    acf: {
      image: AcfImageObject
    }
  }
}

export const RaCardBlogCategory = ({ type, card }: IRaCardBlogCategory) => {
  return (
    <div className={styles['card']}>
      <RaPaper
        noPadding
        link={{
          href: stripFrontendUrl(card.link),
          title: card.name,
        }}
        hoverAnimations={['image-zoom', 'shadow']}>
        <div
          data-animate="true"
          className={styles['card__image']}>
          <RaImage
            width={400}
            height={225}
            image={card.acf.image}
            tornEdge
            tornEdgeColor="var(--color-white)"
            maxWidth
          />
        </div>
        <div className={styles['card__content']}>
          <div className={styles['card__content__badge']}>
            <RaTypography
              size="s"
              component="span"
              color="light">
              {translate('BLOGCATEGORY')}
            </RaTypography>
          </div>
          <RaHeading
            component="h4"
            noPadding>
            {card.name}
          </RaHeading>
        </div>
      </RaPaper>
    </div>
  )
}
