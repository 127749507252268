'use client'
import { useMemo, useState } from 'react'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import type { CartItemProps } from '@typings'
import {
  RaButton,
  RaHeading,
  RaIcon,
  RaImage,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { getCurrentEnv } from '@utilities/helpers/core.helpers'
import {
  Loop,
  stripFrontendUrl,
  translate,
  unescapeHTML,
} from '@utilities/helpers/core.helpers'

import styles from './ra-card-wishlist.module.scss'

import { useCart } from '@/contexts/cart.context'
import { useSettings } from '@/contexts/settings.context'
import { useWishlist } from '@/contexts/wishlist.context'
import { useDebounce } from '@/utilities/hooks/useDebounce.hooks'

const RaAccordion = dynamic(() =>
  import('@components/features/RaAccordion/RaAccordion').then(
    mod => mod.RaAccordion
  )
)

const RaKeyfactWithoutIcon = dynamic(() =>
  import('@components/features/RaKeyfactWithoutIcon/RaKeyfactWithoutIcon').then(
    mod => mod.RaKeyfactWithoutIcon
  )
)

interface IRaCardWishlist {
  item: CartItemProps
  index: number
}

export const RaCardWishlist = ({ item, index }: IRaCardWishlist) => {
  const { removeFromWishlist } = useWishlist()
  const { isFamilySite } = useSettings()
  const { cartItems, addToCart } = useCart()
  const isErlebe = getCurrentEnv() === 'ERLEBE'

  const [showTimeout, setShowTimeout] = useState(false)

  const existsInCart = useMemo(() => {
    if (!cartItems?.length) return false
    return cartItems.find(({ cartId }) => item.cartId === cartId)
  }, [cartItems])

  const addToCartLabel = useMemo(() => {
    switch (item.type) {
      case 'rundreise':
        return translate('ADD_TO_TRAVELPLAN_ROUNTRIP')

      case 'reise-baustein':
        return translate('ADD_TO_TRAVELPLAN_BUILDINGBLOCK')

      case 'variant':
      case 'additional_service':
        return translate('ADD_TO_TRAVELPLAN_ADDITIONAL_SERVICE')
    }
  }, [item.type])

  const wishlistIcon = useMemo(() => {
    if (isFamilySite) return ICONS.WISHLIST_ACTIVE_FAMILY
    return isErlebe ? ICONS.WISHLIST_ACTIVE_ERLEBE : ICONS.WISHLIST_ACTIVE
  }, [isErlebe])

  const handleRemoveFromWishlist = useDebounce(() => {
    setShowTimeout(val => {
      if (val) {
        removeFromWishlist(item)
      }
      return false
    })
  }, 2500)

  if (showTimeout) {
    return (
      <div className={styles['card__undo']}>
        <RaTypography>{translate('REMOVED_FROM_WISHLIST')}</RaTypography>
        <RaButton
          variant="secondary-dark"
          label={translate('UNDO_REMOVE_WISHLIST')}
          onClick={() => setShowTimeout(false)}
        />
      </div>
    )
  }

  return (
    <li
      key={`${item.cartId}-${index}`}
      className={styles['card']}>
      <ErrorBoundary>
        <div className={styles['card__image']}>
          <RaImage
            image={item.image}
            width={116}
            height={82}
            rotate={index % 2 === 0 ? 'left' : 'right'}
          />
        </div>
        <div className={styles['card__content']}>
          {item.link?.url ? (
            <Link
              href={stripFrontendUrl(item.link.url)}
              title={item.link.title}>
              <RaHeading component="h4">{item.title}</RaHeading>
            </Link>
          ) : (
            <RaHeading component="h4">{item.title}</RaHeading>
          )}
          {item.keyFacts && (
            <ul className={styles['card__keyfacts']}>
              {Loop(item.keyFacts, (fact, i: number) => {
                fact.title = translate(fact.title)
                return (
                  <RaKeyfactWithoutIcon
                    item={fact}
                    key={i}
                  />
                )
              })}
            </ul>
          )}
          {item.teaser && (
            <RaAccordion
              variant="read-more"
              title={translate('MORE_INFO')}
              content={<RaRichText content={unescapeHTML(item.teaser)} />}
            />
          )}
          {!existsInCart && (
            <div className={styles['card__content__add-to-travelplan']}>
              <RaButton
                label={addToCartLabel}
                onClick={() => addToCart([item], true)}
                variant="secondary-dark"
                endIcon={
                  <RaIcon
                    icon={ICONS.SUITCASE_CONTAINED}
                    color="dark"
                    badge="+"
                    badgeTextColor="var(--color-body-dark)"
                    badgeColor="#FFC700"
                  />
                }
              />
            </div>
          )}
        </div>
        <div className={styles['card__remove']}>
          {existsInCart && (
            <div className={styles['card__remove__item']}>
              <RaIcon
                icon={ICONS.SUITCASE_CONTAINED}
                color="dark"
              />
            </div>
          )}
          <div className={styles['card__remove__item']}>
            <RaButton
              onClick={() => {
                setShowTimeout(true)
                handleRemoveFromWishlist()
              }}
              variant="icon"
              icon={wishlistIcon}
            />
          </div>
        </div>
      </ErrorBoundary>
    </li>
  )
}
