'use client'
import { useEffect, useState } from 'react'

const MOBILE_BREAKPOINT = 380
const TABLET_BREAKPOINT = 776

export const useViewport = () => {
  const [isMobile, setIsMobile] = useState(true)
  const [isTablet, setIsTablet] = useState(true)
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)

  const handleSizeChange = () => {
    const W = window.innerWidth
    const H = window.innerHeight
    setWidth(W)
    setHeight(H)
    if (W <= MOBILE_BREAKPOINT) setIsMobile(true)
    if (W > MOBILE_BREAKPOINT) setIsMobile(false)
    if (W <= TABLET_BREAKPOINT) setIsTablet(true)
    if (W > TABLET_BREAKPOINT) setIsTablet(false)
  }

  useEffect(() => {
    handleSizeChange()
    window.addEventListener('resize', handleSizeChange)
    window.addEventListener('orientationchange', handleSizeChange)
    return () => {
      window.removeEventListener('resize', handleSizeChange)
      window.removeEventListener('orientationchange', handleSizeChange)
    }
  }, [])

  return { isMobile, isTablet, width, height }
}
