'use client'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'
import Link from 'next/link'

import type { CartItemProps } from '@typings'
import {
  RaButton,
  RaHeading,
  RaImage,
  RaRichText,
} from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { useCart } from '@contexts/cart.context'
import {
  Loop,
  stripFrontendUrl,
  translate,
  unescapeHTML,
} from '@utilities/helpers/core.helpers'

import styles from './ra-card-travelplan.module.scss'

const RaAccordion = dynamic(() =>
  import('@components/features/RaAccordion/RaAccordion').then(
    mod => mod.RaAccordion
  )
)

const RaKeyfactWithoutIcon = dynamic(() =>
  import('@components/features/RaKeyfactWithoutIcon/RaKeyfactWithoutIcon').then(
    mod => mod.RaKeyfactWithoutIcon
  )
)

interface IRaCardTravelplan {
  item: CartItemProps
  index: number
}

export const RaCardTravelplan = ({ item, index }: IRaCardTravelplan) => {
  const cart = useCart()

  return (
    <li
      key={`${item.cartId}-${index}`}
      className={styles['card']}>
      <ErrorBoundary>
        <div className={styles['card__image']}>
          <RaImage
            image={item.image}
            width={116}
            height={82}
            rotate={index % 2 === 0 ? 'left' : 'right'}
          />
        </div>
        <div className={styles['card__content']}>
          {item.link?.url ? (
            <Link
              href={stripFrontendUrl(item.link.url)}
              title={item.link.title}>
              <RaHeading component="h4">{item.title}</RaHeading>
            </Link>
          ) : (
            <RaHeading component="h4">{item.title}</RaHeading>
          )}
          {item.keyFacts && (
            <ul className={styles['card__keyfacts']}>
              {Loop(item.keyFacts, (fact, i: number) => {
                fact.title = translate(fact.title)
                return (
                  <RaKeyfactWithoutIcon
                    item={fact}
                    key={i}
                  />
                )
              })}
            </ul>
          )}
          {item.teaser && (
            <RaAccordion
              variant="read-more"
              title={translate('MORE_INFO')}
              content={<RaRichText content={unescapeHTML(item.teaser)} />}
            />
          )}
          <div className={styles['card__controls']}>
            {index !== 0 && (
              <RaButton
                onClick={() => cart.moveIndex('up', item)}
                variant="icon"
                icon={ICONS.CHEVRON_UP}
                color="primary"
              />
            )}
            {index < cart.cartItems?.length - 1 && (
              <RaButton
                onClick={() => cart.moveIndex('down', item)}
                variant="icon"
                icon={ICONS.CHEVRON_DOWN}
                color="primary"
              />
            )}
          </div>
        </div>
        <div className={styles['card__remove']}>
          <RaButton
            onClick={() => cart.removeFromCart(item)}
            variant="icon"
            icon={ICONS.CLOSE_ICON}
            color="primary"
          />
        </div>
      </ErrorBoundary>
    </li>
  )
}
