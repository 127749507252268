'use client'
import { useMemo } from 'react'
import ErrorBoundary from '@error-boundary'
import dynamic from 'next/dynamic'

import type { AdditionalServiceItemProps } from '@typings'
import {
  RaHeading,
  RaImage,
  RaPaper,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'
import { capitalize, Loop, translate } from '@utilities/helpers/core.helpers'

import styles from './ra-card-additional-service.module.scss'

const RaKeyfactWithoutIcon = dynamic(() =>
  import('@components/features/RaKeyfactWithoutIcon/RaKeyfactWithoutIcon').then(
    mod => mod.RaKeyfactWithoutIcon
  )
)

const RaButtonAddToCart = dynamic(() =>
  import('@components/features/RaButtonAddToCart/RaButtonAddToCart').then(
    ({ RaButtonAddToCart }) => RaButtonAddToCart
  )
)

const SimpleCarousel = dynamic(() =>
  import('@components/carousels/SimpleCarousel/SimpleCarousel').then(
    mod => mod.SimpleCarousel
  )
)
interface RaCardAdditionalServiceProps {
  card: AdditionalServiceItemProps
  index: number
  parentId: string
}

export const RaCardAdditionalService = ({
  card,
  index,
  parentId,
}: RaCardAdditionalServiceProps) => {
  const slides = useMemo(() => {
    if (!card.slideshow) return null
    return Loop(card.slideshow, (img, i) => {
      const source = img
      const width = img.width || img?.sizes?.['medium-width'] || img.width
      const height = img.height || img?.sizes?.['medium-height'] || img.height
      return (
        <RaImage
          width={width}
          height={height}
          image={source}
          key={i}
        />
      )
    })
  }, [card.slideshow])

  return (
    <RaPaper
      padding={5}
      tornEdge>
      <ErrorBoundary>
        <div className={styles['card']}>
          {slides?.length && <SimpleCarousel slides={slides} />}
          <div className={styles['card__content']}>
            <div className={styles['card__header']}>
              <RaTypography
                component="span"
                size="s"
                weight="bold"
                color="primary">
                {index + 1}
              </RaTypography>
              <RaTypography
                component="span"
                size="s"
                weight="bold">
                {capitalize(translate('BB_ADDITIONAL_SERVICES_VARIANT'))}
              </RaTypography>
            </div>
            <RaHeading component="h4">{card.headline}</RaHeading>
            <ul className={styles['content__list']}>
              <RaKeyfactWithoutIcon
                item={{
                  title: translate('TRIP_PRICE'),
                  text: card.traveling_price,
                  remove_from_detail: false,
                  teaser_checkbox: false,
                }}
              />
              <RaKeyfactWithoutIcon
                item={{
                  title: translate('TRIP_DEPARTURE'),
                  text: card.services,
                  remove_from_detail: false,
                  teaser_checkbox: false,
                }}
              />
            </ul>
            <RaRichText content={card.content} />
            <div className={styles['card__add-to-cart']}>
              <RaButtonAddToCart
                parentId={parentId}
                item={card}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </RaPaper>
  )
}
