'use client'
import dynamic from 'next/dynamic'

export const ACF_LAZY_BLOCKS = {
  blog_details: dynamic(() =>
    import('@components/acf/AcfBlogDetails/AcfBlogDetails').then(
      mod => mod.AcfBlogDetails
    )
  ),
  breadcrumbs: dynamic(() =>
    import('@components/acf/AcfBreadcrumbs/AcfBreadCrumbs').then(
      mod => mod.AcfBreadCrumbs
    )
  ),
  card_block: dynamic(() =>
    import('@components/acf/AcfCardBlock/AcfCardBlock').then(
      mod => mod.AcfCardBlock
    )
  ),
  card_grid: dynamic(() =>
    import('@components/acf/AcfCardGrid/AcfCardGrid').then(
      mod => mod.AcfCardGrid
    )
  ),
  center_image: dynamic(() =>
    import('@components/acf/AcfCenterImage/AcfCenterImage').then(
      mod => mod.AcfCenterImage
    )
  ),
  columns: dynamic(() =>
    import('@components/acf/AcfColumns/AcfColumns').then(mod => mod.AcfColumns)
  ),
  cookie_settings: dynamic(() =>
    import('@components/acf/AcfCookieSettings/AcfCookieSettings').then(
      mod => mod.AcfCookieSettings
    )
  ),
  cta_section: dynamic(() =>
    import('@components/acf/AcfCtaSection/AcfCtaSection').then(
      mod => mod.AcfCtaSection
    )
  ),
  eventbrite: dynamic(() =>
    import('@components/acf/AcfEventBrite/AcfEventBrite').then(
      mod => mod.AcfEventBrite
    )
  ),
  expansionpanel: dynamic(() =>
    import('@components/acf/AcfExpansionPanel/AcfExpansionPanel').then(
      mod => mod.AcfExpansionPanel
    )
  ),
  form: dynamic(() =>
    import('@components/acf/AcfForm/AcfForm').then(mod => mod.AcfForm)
  ),
  form_dialog: dynamic(() =>
    import('@components/acf/AcfFormDialog/AcfFormDialog').then(
      mod => mod.AcfFormDialog
    )
  ),
  hero: dynamic(() =>
    import('@components/acf/AcfHero/AcfHero').then(mod => mod.AcfHero)
  ),
  icon_block: dynamic(() =>
    import('@components/acf/AcfIconBlock/AcfIconBlock').then(
      mod => mod.AcfIconBlock
    )
  ),
  inspiration_blocks: dynamic(() =>
    import('@components/acf/AcfInspirationBlocks/AcfInspirationBlocks').then(
      mod => mod.AcfInspirationBlocks
    )
  ),
  navigation: dynamic(() =>
    import('@components/acf/AcfNavigation/AcfNavigation').then(
      mod => mod.AcfNavigation
    )
  ),
  overview_filter: dynamic(() =>
    import('@components/acf/AcfOverviewFilter/AcfOverviewFilter').then(
      mod => mod.AcfOverviewFilter
    )
  ),
  overview_results: dynamic(() =>
    import('@components/acf/AcfOverviewResults/AcfOverviewResults').then(
      mod => mod.AcfOverviewResults
    )
  ),
  search: dynamic(() =>
    import('@components/acf/AcfSearch/AcfSearch').then(mod => mod.AcfSearch)
  ),
  section_with_sidebar: dynamic(() =>
    import('@components/acf/AcfSectionWithSidebar/AcfSectionWithSidebar').then(
      mod => mod.AcfSectionWithSidebar
    )
  ),
  sub_introduction: dynamic(() =>
    import('@components/acf/AcfSubIntroduction/AcfSubIntroduction').then(
      mod => mod.AcfSubIntroduction
    )
  ),
  takeout_section: dynamic(() =>
    import('@components/acf/AcfTakeoutSection/AcfTakeoutSection').then(
      mod => mod.AcfTakeoutSection
    )
  ),
  travel_specialist_promo: dynamic(() =>
    import(
      '@components/acf/AcfTravelSpecialistPromo/AcfTravelSpecialistPromo'
    ).then(mod => mod.AcfTravelSpecialistPromo)
  ),
  travel_specialist_quote: dynamic(() =>
    import(
      '@components/acf/AcfTravelSpecialistQuote/AcfTravelSpecialistQuote'
    ).then(mod => mod.AcfTravelSpecialistQuote)
  ),
  travelplan: dynamic(() =>
    import('@components/acf/AcfTravelplan/AcfTravelplan').then(
      mod => mod.AcfTravelplan
    )
  ),
  wishlist: dynamic(() =>
    import('@components/acf/AcfWishlist/AcfWishlist').then(
      mod => mod.AcfWishlist
    )
  ),
  trustpilot: dynamic(() =>
    import('@components/acf/AcfTrustpilot/AcfTrustpilot').then(
      mod => mod.AcfTrustpilot
    )
  ),
  why_book_at: dynamic(() =>
    import('@components/acf/AcfWhyBookAt/AcfWhyBookAt').then(
      mod => mod.AcfWhyBookAt
    )
  ),
  news: dynamic(() =>
    import('@components/acf/AcfNews/AcfNews').then(mod => mod.AcfNews)
  ),
  as_featured_in: dynamic(() =>
    import('@components/acf/AcfAsFeaturedIn/AcfAsFeaturedIn').then(
      mod => mod.AcfAsFeaturedIn
    )
  ),
}
