import { useMemo } from 'react'
import {
  RaButton,
  RaHeading,
  RaIcon,
  RaImage,
  RaPaper,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'
import {
  stripFrontendUrl,
  translate,
  unescapeHTML,
} from '@utilities/helpers/core.helpers'
import { ICONS } from '@constants/icons.constants'
import { getCurrentEnv } from '@utilities/helpers/core.helpers'
import {
  AcfImageObject,
  BuildingBlockKeyFactsRepeaterItemProps,
  RoundtripKeyFactsRepeaterItemProps,
} from '@typings'

import styles from './ra-card-trip.module.scss'
interface IRaCardTripRoundtrip {
  type: 'roundtrip'
  card: {
    type: 'roundtrip'
    id: number
    slug: string
    link: string
    title: {
      rendered: string
    }
    featured_media: number
    featured_image: AcfImageObject
    acf: {
      rt_subline?: string
      rt_keyfacts_repeater?: RoundtripKeyFactsRepeaterItemProps[]
      rt_modul_teaser_gallery?: AcfImageObject[]
    }
  }
}

interface IRaCardTripBuildingblock {
  type: 'buildingblock'
  card: {
    type: 'buildingblock'
    id: number
    slug: string
    link: string
    title: {
      rendered: string
    }
    featured_media: number
    featured_image: AcfImageObject
    acf: {
      bb_subline?: string
      bb_keyfacts_repeater?: BuildingBlockKeyFactsRepeaterItemProps,
      bb_info_image?: AcfImageObject[]
    }
  }
}

type IRaCardTrip = IRaCardTripRoundtrip | IRaCardTripBuildingblock

export const RaCardTrip = ({ type, card }: IRaCardTrip) => {
  const isRickshaw = getCurrentEnv() === 'RICKSHAW'

  const TYPE = type === 'roundtrip' ? 'ROUNDTRIP' : 'BUILDING_BLOCK'

  const LABEL =
    type === 'roundtrip'
      ? translate('VIEW_ROUNDTRIP')
      : translate('VIEW_BUILDINGBLOCK')

  const priceTitle = translate('TRIP_PRICE')
  const durationTitle = translate('TRIP_DURATION')
  const itineraryTitle = isRickshaw
    ? translate('ROUNDTRIP')
    : translate('TRIP_SCHEDULE')

  const repeater = 
   type === 'roundtrip' ? card?.acf?.rt_keyfacts_repeater : card?.acf?.bb_keyfacts_repeater

  const tripPrice = useMemo(() => {
    if (!repeater) return null
    const priceObject = repeater[0].output_type_table_repeater.find(
      ({ title }) => title === priceTitle
    )
    if (!priceObject) return null
    return priceObject.text
  }, [repeater])

  const tripItinerary = useMemo(() => {
    const object = repeater[0].output_type_table_repeater.find(
      ({ title }) => title === itineraryTitle
    )
    return object?.text ? object?.text : null
  }, [repeater])

  const tripLocation = useMemo(() => {
    const object = repeater[0].output_type_table_repeater.find(
      ({ title }) => title === 'Location'
    )
    return object?.text ? object?.text : null
  }, [repeater])

  const tripDuration = useMemo(() => {
    const durationObject = repeater[0].output_type_table_repeater.find(
      ({ title }) => title === durationTitle
    )
    return durationObject?.text ? durationObject?.text : null
  }, [repeater])

  const image = type === 'roundtrip' ? card.acf.rt_modul_teaser_gallery?.[0] : card.acf.bb_info_image?.[0]

  return (
    <div className={styles['card']}>
      <RaPaper
        tornEdge
        noPadding
        hoverAnimations={['image-zoom', 'shadow']}
        link={{
          title: card.title.rendered,
          href: stripFrontendUrl(card.link),
        }}>
        <div
          className={styles['card__image']}
          data-animate="true">
          <RaImage
            width={370}
            image={image || card.featured_image}
            maxWidth
          />
        </div>
        <div className={styles['card__content']}>
          <div className={styles['card__content__badge']}>
            <RaTypography
              size="s"
              component="span"
              color="light">
              {translate(TYPE)?.toUpperCase()}
            </RaTypography>
          </div>
          <div className={styles['card__content__details']}>
            <div className={styles['card__content__details__container']}>
              <RaHeading component="h3">
                {unescapeHTML(card.title.rendered)}
              </RaHeading>
              <div className={styles['card__content__keyfact']}>
                <RaIcon
                  color="dark"
                  icon={ICONS.LOCATION_MARKER}
                />
                <RaTypography component="span">
                  {tripItinerary || tripLocation}
                </RaTypography>
              </div>
              <div className={styles['card__content__keyfact']}>
                <RaIcon
                  color="dark"
                  icon={ICONS.CALENDAR}
                />
                <RaTypography component="span">{tripDuration}</RaTypography>
              </div>
              <div className={styles['card__content__keyfact']}>
                <RaIcon
                  color="dark"
                  icon={isRickshaw ? ICONS.CURRENCY_GBP : ICONS.CURRENCY_EUR}
                />
                <RaRichText content={tripPrice} />
              </div>
            </div>
          </div>
          <div className={styles['card__content__footer']}>
            <RaButton
              label={LABEL}
              tabIndex={-1}
            />
          </div>
        </div>
      </RaPaper>
    </div>
  )
}
