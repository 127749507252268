'use client'
import { RaImage } from '@components/core/index.core'
import { Loop } from '@utilities/helpers/core.helpers'

import styles from './ra-hero.module.scss'

import { useSettings } from '@contexts/settings.context'

export const RaHeroIndustryIcons = () => {
  const { themeSettings } = useSettings()

  if (!themeSettings) return null

  const width = 36
  const height = 36

  return (
    <div className={styles['hero__industry-icons']}>
      <ul>
        {Loop(themeSettings.branche_logos, (logo, i) => {
          return (
            <li
              key={i}
              style={{
                width,
                height,
              }}>
              <RaImage
                image={logo}
                width={width}
                height={height}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
