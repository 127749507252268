'use client'
import { memo, useEffect, useMemo, useState } from 'react'
import { Loop } from '@utilities/helpers/core.helpers'
import { RaTabs } from '@components/features/RaTabs/RaTabs'
import { usePathname, useRouter } from 'next/navigation'

import type { AcfNavigationProps } from '@typings'
import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

export const AcfNavigation = memo(({ items }: AcfNavigationProps) => {
  const pathname = usePathname()
  const router = useRouter()
  const [activeTabIndex, setActiveTabIndex] = useState(null)

  const tabListJSON: any[] = useMemo(() => {
    return Loop(items, (item, i) => {
      return {
        value: item.link?.url,
        text: item.link?.title,
        target: item.link?.target,
        isSelected: activeTabIndex === i,
      }
    })
  }, [items, activeTabIndex])

  const onSelect = (url: string) => {
    router.push(stripFrontendUrl(url))
  }

  useEffect(() => {
    const index = items.findIndex(
      ({ link }) => stripFrontendUrl(link.url) === pathname
    )
    setActiveTabIndex(index)
  }, [pathname])

  return (
    <RaTabs
      activeTabIndex={activeTabIndex}
      tabsData={tabListJSON}
      onTabSelect={onSelect}
      isLink
    />
  )
})

AcfNavigation.displayName = 'AcfNavigation'
