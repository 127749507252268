'use client'
import { ACF_LAZY_BLOCKS } from '@constants/acf-lazy-blocks.constant'
import { AcfBlock } from '@typings'

interface AcfLazyWrapper {
  block: AcfBlock
  index: number
}

export const AcfLazyWrapper = ({ block, index }) => {
  const Component = ACF_LAZY_BLOCKS[block.acf_fc_layout]

  if (!Component) {
    console.warn('No component found: ', block.acf_fc_layout)
    return null
  }
  return (
    <Component
      key={`acf-block--${block.acf_fc_layout}--${index}`}
      index={index}
      {...block}
    />
  )
}
