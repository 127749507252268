'use client'
import { memo, useRef } from 'react'
import {
  RaBackgroundImage,
  RaButton,
  RaHeading,
  RaPortal,
  RaTypography,
} from '@components/core/index.core'
import Link from 'next/link'

import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'
import { AcfInspirationBlock } from '@typings'

import styles from './ra-card-inspiration.module.scss'

export const RaCardInspiration = memo((card: AcfInspirationBlock) => {
  const portalTriggerEl = useRef(null)
  return (
    <Link
      ref={portalTriggerEl}
      href={stripFrontendUrl(card.cta.url)}
      title={card.cta.title}
      target={card.cta.target}
      className={styles['card']}>
      <RaBackgroundImage
        image={card.image}
        overlayOpacity={0.25}>
        <div className={styles['card__content']}>
          {card.title && (
            <div className={styles['card__portal']}>
              <RaPortal
                ref={portalTriggerEl}
                size={352}>
                <div className={styles['card__portal__content']}>
                  {card.text && (
                    <RaTypography
                      color="light"
                      size="l"
                      weight="semibold"
                      align="center"
                      dropShadow>
                      {card.text}
                    </RaTypography>
                  )}
                  <RaHeading
                    alignment="center"
                    component="h4"
                    color="light"
                    noPadding
                    dropShadow>
                    {card.title}
                  </RaHeading>
                </div>
              </RaPortal>
            </div>
          )}

          {card.cta && (
            <RaButton
              label={card.cta.title}
              tabIndex={-1}
            />
          )}
        </div>
      </RaBackgroundImage>
    </Link>
  )
})
