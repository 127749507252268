'use client'
import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import { RaButton } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import { useViewport } from '@utilities/hooks/useViewport.hooks'

import styles from './ra-tabs.module.scss'

export const RaTabsSlider = ({ tabs, activeTabIndex }) => {
  const containerRef = useRef(null)
  const tabsRef = useRef(null)
  const { width } = useViewport()
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollRight, setScrollRight] = useState(200)

  const handleScrollChange = e => {
    const { scrollLeft, scrollWidth, clientWidth } = e.target
    setScrollLeft(scrollLeft)
    const right = scrollWidth - (scrollLeft + clientWidth)
    setScrollRight(right)
  }

  const goToStart = () => {
    if (tabsRef?.current) {
      tabsRef.current.scrollTo({ left: 0, behavior: 'smooth' })
    }
  }

  const goToEnd = () => {
    const posRight = tabsRef.current.scrollWidth
    tabsRef.current.scrollTo({ left: posRight, behavior: 'smooth' })
  }

  const renderControls = useMemo(() => {
    return width < tabsRef.current?.scrollWidth
  }, [width])

  useEffect(() => {
    if (tabsRef?.current) {
      tabsRef.current.addEventListener('scroll', handleScrollChange)
    }

    return () =>
      tabsRef?.current?.removeEventListener('scroll', handleScrollChange)
  }, [tabsRef?.current])

  useEffect(() => {
    const activeEl = tabsRef?.current?.children?.[0]?.children?.[activeTabIndex]
    const offset = tabsRef?.current?.clientWidth / 2
    if (activeEl) {
      const scrollLeft = activeEl.offsetLeft
      tabsRef?.current.scrollTo({
        left: scrollLeft - offset + activeEl.clientWidth / 2,
        behavior: 'smooth',
      })
    }
  }, [activeTabIndex])

  return (
    <div
      ref={containerRef}
      className={styles['ra-tabs--mobile__button-wrapper']}>
      {renderControls && (
        <div
          className={clsx(
            styles['ra-tabs--mobile__button'],
            styles['ra-tabs--mobile__button--left'],
            scrollLeft > 0 && styles['ra-tabs--mobile__button--active']
          )}>
          <RaButton
            variant="icon"
            icon={ICONS.CHEVRON_LEFT}
            onClick={goToStart}
          />
        </div>
      )}
      <div
        ref={tabsRef}
        className={styles['ra-tabs--mobile__tabs-wrapper']}>
        <div className={clsx(styles['ra-tabs--mobile__tab-list'])}>{tabs}</div>
      </div>
      {renderControls && (
        <div
          className={clsx(
            styles['ra-tabs--mobile__button'],
            styles['ra-tabs--mobile__button--right'],
            // Some margins needed for padding
            scrollRight > 15 && styles['ra-tabs--mobile__button--active']
          )}>
          <RaButton
            variant="icon"
            icon={ICONS.CHEVRON_RIGHT}
            onClick={goToEnd}
          />
        </div>
      )}
    </div>
  )
}
