'use client'
import { useMemo } from 'react'

import {
  RaHeading,
  RaImage,
  RaPaper,
  RaTypography,
} from '@components/core/index.core'
import { AcfImageObject, WordpressPostFeaturedImage } from '@typings'
import { translate, unescapeHTML } from '@utilities/helpers/core.helpers'
import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

import styles from './ra-card-blog.module.scss'

interface IRaCardBlog {
  type?: 'region' | 'building_block' | 'blog_post'
  card: {
    type: 'blog_post'
    id: number
    slug: string
    link: string
    title: {
      rendered: string
    }
    featured_media: number
    featured_image: AcfImageObject | WordpressPostFeaturedImage
  }
}

export const RaCardBlog = ({ type, card }: IRaCardBlog) => {
  const badgeLabel = useMemo(() => {
    let result = null

    switch (type) {
      case 'building_block':
        result = translate('BUILDING_BLOCK')
        break
      case 'region':
        result = translate('REGION')
        break
      case 'blog_post':
        result = 'Blog'
        break
    }

    return result
  }, [type])

  return (
    <div className={styles['card']}>
      <RaPaper
        noPadding
        link={{
          href: stripFrontendUrl(card.link),
          title: card.title.rendered,
        }}
        hoverAnimations={['image-zoom', 'shadow']}>
        <div
          data-animate="true"
          className={styles['card__image']}>
          <RaImage
            width={384}
            height={225}
            image={card.featured_image}
            tornEdge
            tornEdgeColor="var(--color-white)"
          />
        </div>
        <div className={styles['card__content']}>
          {type && badgeLabel && (
            <div className={styles['card__content__badge']}>
              <RaTypography
                size="s"
                component="span"
                color="light">
                {badgeLabel?.toUpperCase()}
              </RaTypography>
            </div>
          )}
          <RaHeading
            component="h4"
            noPadding>
            {unescapeHTML(card.title.rendered)}
          </RaHeading>
        </div>
      </RaPaper>
    </div>
  )
}
