'use client'
import { RaButton, RaTypography } from '@components/core/index.core'
import { translate } from '@utilities/helpers/core.helpers'

export const RaLoadingError = () => {
  return (
    <div>
      <RaTypography>{translate('LOADING_ERROR')}</RaTypography>
      <RaButton
        id="product-fetch-fail"
        label={translate('RELOAD_PAGE')}
        onClick={() => location.reload()}
      />
    </div>
  )
}
